<template>
  <div class="editable-input" :key="listeKey">
    <div class="edit-view">
      <dynamic-input-vuetify-ressource
        :input="{
          field_id: field.id,
          disabledInput: false,
          group_id: field.group_id,
          type: field.input_type,
          type_options: field.type,
          options: field.options ? field.options.data : [],
          name: field.key,
          label: field.name,
          links: field.links ? field.links : [],
          selected: field.selection_type,
          valuePossible:
            field.possible_values && field.possible_values.data
              ? field.possible_values.data
              : field.select_options && field.select_options.length
              ? field.select_options
              : field.possible_values,
          formatDate: field.format ? true : false,
          required: field.required,
          type_projet_id: type_projet_id
        }"
        :value="newValue"
        v-model="newValue"
        @save="update"
      ></dynamic-input-vuetify-ressource>
    </div>
  </div>
</template>

<script>
import dynamicInputVuetifyRessource from '@/components/ui/dynamic-form/dynamic-input-vuetify-ressource.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'EditableCustomFieldForm',
  components: {
    dynamicInputVuetifyRessource
  },
  props: {
    field: { required: true },
    value: { required: true },
    readonly: { required: false },
    fieldKeyEdit: { required: false },
    required: { required: false },
    typeRessource: { required: true },
    type_projet_id: { required: false }
  },
  data() {
    return {
      editing: false,
      newValue: this.value,
      listeKey: 0
    }
  },
  async mounted() {
    this.newValue = this.value
  },
  watch: {
    value(val) {
      this.newValue = val
    }
  },
  methods: {
    ...mapActions(['fetchAllTypeResource']),
    resetComponentEditableDynamic() {
      this.listeKey++
    },
    update(value) {
      this.newValue = value
      // Construisez l'objet payload avec les champs mis à jour
      const mappingFields = []
      if (this.typeRessource && this.typeRessource.length) {
        this.typeRessource.map(field => {
          if (field.is_header !== 1) {
            // Mettez à jour les valeurs des champs pour chaque catégorie
            if (JSON.stringify(field) === JSON.stringify(this.field)) {
              this.updateFieldValuesNull(
                this.typeRessource,
                this.field.key,
                value
              )
            }
            let valueField =
              field.value && field.value.id ? field.value.id : field.value
            mappingFields.push({
              value:
                valueField == null
                  ? field.lastValue && field.lastValue.id
                    ? field.lastValue.id
                    : field.lastValue
                  : valueField && valueField.length && Array.isArray(valueField)
                  ? valueField.join(', ')
                  : valueField.toString(),
              id: field.id
            })
          }
          return field
        })
        this.$nextTick(() => {
          this.$emit(
            'updateFunction',
            mappingFields.filter(item => item != null)
          )
        })
      }
    },
    // Fonction pour mettre à jour la valeur d'un champ
    updateFieldValuesNull(fields, key, newValue) {
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].key === key) {
          // Stockez la dernière valeur du champ
          fields[i].lastValue = fields[i].value
          // Mettez à jour la valeur du champ
          fields[i].value = newValue
          break
        }
      }
    }
  },
  computed: {
    ...mapGetters([''])
  }
}
</script>
<style lang="scss" scoped>
.editable-input {
  .edit-view {
    &.input-radio {
      border: 1px solid #9e9e9e;
      padding: 8px;
      width: 100%;
      border-radius: 4px;
    }
    &.required {
      border: 2px solid #ff5252;
    }
  }
}
</style>
