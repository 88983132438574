var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.listeKey,staticClass:"editable-input"},[_c('div',{staticClass:"edit-view"},[_c('dynamic-input-vuetify-ressource',{attrs:{"input":{
        field_id: _vm.field.id,
        disabledInput: false,
        group_id: _vm.field.group_id,
        type: _vm.field.input_type,
        type_options: _vm.field.type,
        options: _vm.field.options ? _vm.field.options.data : [],
        name: _vm.field.key,
        label: _vm.field.name,
        links: _vm.field.links ? _vm.field.links : [],
        selected: _vm.field.selection_type,
        valuePossible:
          _vm.field.possible_values && _vm.field.possible_values.data
            ? _vm.field.possible_values.data
            : _vm.field.select_options && _vm.field.select_options.length
            ? _vm.field.select_options
            : _vm.field.possible_values,
        formatDate: _vm.field.format ? true : false,
        required: _vm.field.required,
        type_projet_id: _vm.type_projet_id
      },"value":_vm.newValue},on:{"save":_vm.update},model:{value:(_vm.newValue),callback:function ($$v) {_vm.newValue=$$v},expression:"newValue"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }